import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: '0rem 0px',
    padding: theme.spacing(0, 2),
    wordBreak: 'break-word',

    [theme.breakpoints.up('xs')]: {
      padding: theme.spacing(0, 1),
    },
  },

  link: {
    textDecoration: 'none'
  },
  navBG: {
    background:"#d4d4d4fa",
    borderBottom: "2px solid rgb(15, 174, 79)"
  },
  wordCloud: {
    height: 300,
    width: '50%',
    backgroundColor: 'rgba(212, 212, 212, 0.98)',
    borderRadius: '5px',
    boxShadow: '0px 8px 18px rgba(0,0,0,0.15)',
    padding: theme.spacing(2),
    margin: '8rem auto 5px auto',

    ['@media (max-width:600px)']: {
      width: '96%',
      height: 200,
    },
  },

  SkeletonCard: {
    backgroundColor: '#fff',
    borderRadius: '0.25rem',
    width: '100%',
  },
  img: {
    width: '100%',
    height: 'auto',
  },

  hero: {
    display: 'flex',
    flexFlow: 'column',
    height: '-webkit-fill-available',
    color: 'white',
    /* width: fit-content, */
    alignItems: 'center',
    justifyContent: 'center',
  },

  PopularHeading: {
    margin: theme.spacing(1),
    color: '#fff',
    color: '#FFFFFF',
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: 600,
  },
  content: {
    margin: theme.spacing(2, 0),
    color: '#fff',
    textAlign: 'center',
    fontSize: '1.1rem',
    fontWeight: 600,
  },
  margin5: {
    margin: '5px 0px 10px 0px',
  },
  margin0: {
    margin: theme.spacing(0, 0)
  },
  popularMob: {
    margin: theme.spacing(1),
    color: '#fff',
    textAlign: 'center',
    color: '#FFFFFF',
    fontSize: '1.2rem',
    marginTop: '0px',
    fontWeight: 600,
  },
  margin: {
    margin: 'auto',
    marginBottom:"8px"
  },
  color: {
    color: '#FFFFFF',
    textAlign: "center"
  },
  typo:{
    color: '#fff',
    marginLeft: theme.spacing(2)
  },
  tagsTitle:{
    padding: '10px 20px', 
    color: '#000', 
    fontWeight: '100'
  },
  needHelpTitle:{
    textAlign: 'center',
    padding: theme.spacing(2, 1),

    '& h1':{
      color: '#FFFFFF',
      margin: theme.spacing(2, 0)
    },
    '& h4':{
      textAlign: 'left',
      margin: theme.spacing(3, 2)
    }
  },
  adsByGoogle: {
    width: '320px',
    height: '50px',

    [theme.breakpoints.up('sm')]: {
        width: '468px',
        height: '60px',
    },

    [theme.breakpoints.up('md')]: {
        width: '728px',
        height: '90px',
    },
  },
  
  TopicadsByGoogle: {
    width: '300px',
    height: '250px',

    [theme.breakpoints.up('sm')]: {
      width: '468px',
      height: '60px',
    },

    [theme.breakpoints.up('md')]: {
      width: '728px',
      height: '90px',
    },
  },
  searchTitleContainer: {
    padding: theme.spacing(0, 2, 0.5, 2),

    ['@media (min-width:800px)']: {
      width: '65%',
      margin: 'auto'
    },
  },
  title: {
    color: '#000',
    fontWeight: 'bold',
    width: "auto"
  },
  subtitle: {
    color: '#000'
  },
}));
